import {
  User as PersonIcon,
  TrendingUp as TrendingUpIcon,
} from '@grupo-sbf/motriz-icons/centauro';
import { centauro } from '@grupo-sbf/motriz-tokens/themes';
import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background: #ffffff;
  margin: 0px 0px 16px 0px;
`;

export const HeaderIcon = styled(TrendingUpIcon)`
  width: 32px;
  height: 32px;
`;

export const HeaderTypography = styled(Typography)`
  font-style: normal;
  font-weight: 700 !important;
  font-size: 1.625rem !important;
  line-height: 2rem !important;
  color: ${centauro.color.neutral[300]};
  margin-left: 8px !important;
`;

export const BoxContainer = styled.div`
  max-width: 1200px;

  ${({ theme }) => css`
    padding-top: ${theme.spacing[300]};
  `}
`;

export const BoxRow = styled.div`
  display: flex;
  flex-direction: rowflex;
  align-items: flex-startflex;
  justify-content: flex-startflex;
  flex-wrap: wrapflex;
`;

export const SubtitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 28px;
  margin: 16px 12px;
`;

export const Subtitle = styled(Typography)`
  font-style: normal;
  font-weight: 700 !important;
  font-size: 1.75rem !important;
  line-height: 2rem !important;
  color: ${centauro.color.neutral[600]};
  margin: 0px 8px !important;
`;

export const BoxIcon = styled(PersonIcon)`
  color: ${centauro.color.neutral[600]};
  height: 24px;
  width: 24px;
`;

export const TextError = styled.p`
  font-size: 0.875rem; // 14px
  line-height: 1.125rem; // 20px
  color: black;
  margin: 4px 0 16px 0;
`;
