import { Typography } from '@grupo-sbf/motriz-core';
import {
  ContainerSubheader,
  Container,
  ContainerIcon,
  ContainerTitle,
  Icon,
  ButtonContainer,
} from './Subheader.styled';
import { SubheaderProps } from './Subheader.types';

const Subheader = ({
  breadcrumb,
  icon,
  title,
  subtitle,
  button,
  hasButton,
  secondaryComponent,
  noMargin,
}: SubheaderProps) => {
  return (
    <ContainerSubheader data-testid="container-breadcrumb" visible={!!button}>
      <Container>
        {breadcrumb}
        <ContainerTitle>
          <ContainerIcon>
            <Icon margin={!!noMargin}>{icon}</Icon>
            <Typography type="subtitle" size="large">
              {title}
            </Typography>
            {secondaryComponent}
          </ContainerIcon>
          {subtitle && (
            <Typography type="simple" m={[50, 0, 0, 400]}>
              {subtitle}
            </Typography>
          )}
        </ContainerTitle>
      </Container>
      <ButtonContainer> {hasButton && button}</ButtonContainer>
    </ContainerSubheader>
  );
};

export default Subheader;
