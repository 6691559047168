import styled, { css } from 'styled-components';

type ContainerProps = {
  visible: boolean;
};

type IconProps = {
  margin: boolean;
  children: JSX.Element;
};

export const ContainerSubheader = styled.div<ContainerProps>`
  display: flex;
  ${({ theme }) => css`
    padding-left: ${theme.spacing[100]};
    background: ${theme.color.neutral[100]};
  `}

  ${(props) => props.visible && 'justify-content: space-between'};
`;

export const Container = styled.div`
  width: 100%;

  ${({ theme }) => css`
    padding-top: ${theme.spacing[200]};
    padding-left: ${theme.spacing[300]};
    padding-bottom: ${theme.spacing[200]};
  `}
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.color.neutral[700]};
  `}
`;

export const ContainerTitle = styled.div``;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.medium};
    line-height: ${theme.lineHeight.normal};
  `}
`;

export const Icon = styled.div<IconProps>`
  display: flex;

  ${({ theme, margin }) => css`
    color: ${theme.color.neutral[700]};
    margin-right: ${margin ? theme.spacing[0] : theme.spacing[100]};
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${({ theme }) => css`
    padding-top: ${theme.spacing[200]};
    padding-right: ${theme.spacing[300]};
    padding-bottom: ${theme.spacing[200]};
  `};
`;
